<template>
   
    <div>

     

        <grid-layout ref="gridLayOut"
      :tableOptions="tableOptions"
      :tableData="ExecutorList"
      :data-total="total"
      :table-loading="loading"
      @page-size-change="handleSizeChange" @page-current-change="handleCurrentChange"
      :gridRowBtn="gridRowBtn"
      @grid-kill="killFlow" 
        >
      <template slot="status" slot-scope="{row}" >
        <el-tag :type="historyTagColor(row)">{{historyTagText(row)}}</el-tag>
      </template>
      <template slot="start_time" slot-scope="{row}" >
        {{ getDate(row.start_time) }}
      </template>
      <template slot="end_time" slot-scope="{row}" >
        {{ getDate(row.end_time) }}
      </template>

    </grid-layout>



      
    
    
    
 
 
 
 
 
    <!-- 弹框 -->
    <el-dialog
   :title="$t('cip.dc.taskScheduling.title.Tips')"
   :visible.sync="dialog1"
   width="30%"
   :before-close="handleClose">
   <span>{{ $t('cip.dc.taskScheduling.msg.msg3') }}</span>
   <span slot="footer" class="dialog-footer">
     <el-button @click="dialog1 = false">{{ $t('projectlist.btn.close') }}</el-button>
     <el-button type="primary" @click="dialog1 = false">{{ $t('projectlist.btn.sure') }}</el-button>
   </span>
 </el-dialog>
 
 
 
 </div>
   </template>
   
   <script>
   import {getExecutor} from "@/api/dataAcquisition";
   import eventBus from "@/util/eventBus"
   import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
   import GridLayout from "@/views/components/layout/grid-layout";

   export default {
     name: "task4",
     data(){
       return{
         dialog1:false,
         dialog2:false,
         dialog3:false,
         ExecutorList:[],//正在运行列表
         
        
         total:"", //总数
   listParms:{
  perPage: 20, startIndex: 1
},
page: {
          pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
          currentPage: 1,
          total: 0
        },
      gridRowBtn:[
      {
            label: this.$t('cip.dc.taskScheduling.btn.kill'),
            emit: "grid-kill",
            type: "text",
            icon: ""
          },

          
      ],
      loading: false,
      tableOptions: {
        customRowAddBtn: false,
        menuWidth: 100,
        height:625,
          column: [
          {
            label: this.$t('cip.dc.taskScheduling.label4.ID'),
            prop: "exec_id",
            cell: true,
          },
          
          {
            label: this.$t('cip.dc.taskScheduling.label4.ExecutorID'),
            prop: "executor_id",
            cell: true,
          },
          {
            label: this.$t('cip.dc.taskScheduling.label4.flow'),
            prop: "flow_id",
            cell: true,
          },
            
            {
              label: this.$t('cip.dc.taskScheduling.label4.engineering'),
              prop: "name",
             
            },
          //   {
          //   label: this.$t('cip.dc.taskScheduling.label4.user'),
          //   prop: "submit_user",
          // },
          {
              label: this.$t('cip.dc.taskScheduling.label4.jurisdiction'),
              prop: "address",
             
            },
            {
            label: this.$t('cip.dc.taskScheduling.label4.starttime'),
            prop: "start_time",
            slot:true
          },
          {
              label: this.$t('cip.dc.taskScheduling.label4.Endtime'),
              prop: "end_time",
              slot:true
            },
            {
            label: this.$t('cip.dc.taskScheduling.label4.Losstime'),
            prop: "address2",
          },
          {
            label: this.$t('cip.dc.taskScheduling.label4.state'),
            prop: "status",
            slot:true
          },
          ]
        },

 
       }
     },
     components:{
   GridLayout
  },
     mounted(){
      this.getExecutorList(this.listParms)

      eventBus.$on("Sfourth",res=>{
        this.getExecutorList(this.listParms)
    })
     },
     created(){
      
     },
     methods:{
      getDate(date){ 
    var t = new Date(date).toLocaleString(); 
    return t; 
    },
      historyTagText(item){
      if (item.status == 30) {
        return this.$t('cip.dc.taskScheduling.status.Running')
      } else if (item.status == 50) {
        return this.$t('cip.dc.taskScheduling.status.success')
      } else if (item.status == 70) {
        return this.$t('cip.dc.taskScheduling.status.fail')
      }
    },
    historyTagColor(item){
      if (item.status == 30) {
        return `warning`
      } else if (item.status == 50) {
        return `success`
      } else if (item.status == 70) {
        return `danger`
      }else {
        return ''
      }
    },
      getExecutorList(e){
      getExecutor(e).then(res=>{
        console.log(res,'excutor')
        this.ExecutorList = res.data.data.list
      })
    },

    killFlow(e){
      // console.log(e,"ssdasdasdasdasd");
    }
     
     },
     
     //分页
    handleSizeChange(val) {
        this.listParms.perPage = val.pageSize
        this.getTriggersList(this.listParms)
      },
      handleCurrentChange(val) {
        this.listParms.startIndex = val.currentPage
        this.getTriggersList(this.listParms)
      }
     
     
   }
   </script>
   
   <style scoped>
   .block{
  float:right;
}
   </style>
   