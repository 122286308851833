<template>

  <div>




    


    <grid-layout ref="gridLayOut"
                 :data-total="total"
                 :gridRowBtn="gridRowBtn"
                 :table-loading="loading"
                 :tableData="projectColumns"
                 :tableOptions="tableOptions" @page-size-change="handleSizeChange"
                 @page-current-change="handleCurrentChange"
                 @grid-see="MflowDetail" @grid-ds="DSclick"
                 @grid-dd="Rundd"
    >
      <template slot="modified_time" slot-scope="{row}">
        {{ getDate(row.modified_time) }}
      </template>

    </grid-layout>




    <!-- 弹框 -->
    <el-dialog :before-close="handleClose" :title="$t('cip.dc.taskScheduling.title.Tips')" :visible.sync="dialog1" width="60%">

      <!-- <CzrVueCron :cron.sync="cronVal" /> -->
      <CzrVueCron v-model="cronVal" />
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialog1 = false">{{ $t('cancelText') }}</el-button>
    <el-button style="margin-left: 15px;" type="primary" @click="SureCron">{{ $t('submitText') }}</el-button>
  </span>
    </el-dialog>




    <el-dialog :before-close="handleClose" :title="$t('cip.dc.taskScheduling.title.InformationService')" :visible.sync="dialog2" width="60%">

      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="$t('cip.dc.taskScheduling.title.flowchart')" name="first">
          <g2></g2>



        </el-tab-pane>

        <el-tab-pane :label="$t('cip.dc.taskScheduling.title.tasklist')" name="second">
          <el-table :data="Seedata.resFetchexecflow.nodes" :default-sort="{ prop: 'startTime', }" border
                    style="width: 100%">
            <el-table-column :label="$t('cip.dc.taskScheduling.label1.name')" prop="nestedId">
            </el-table-column>
            <el-table-column :label="$t('cip.dc.taskScheduling.label1.type')" prop="type">
            </el-table-column>
            <!-- <el-table-column prop="address" label="时间轴">
              </el-table-column> -->

            <el-table-column :label="$t('tcip.dc.askScheduling.label4.starttime')" prop="startTime">

              <template slot-scope="scope">{{ new Date(scope.row.startTime).toLocaleString() }}</template>

            </el-table-column>
            <el-table-column :label="$t('cip.dc.taskScheduling.label4.Endtime')" prop="endTime">
              <template slot-scope="scope">{{ new Date(scope.row.endTime).toLocaleString() }}</template>
            </el-table-column>
            <el-table-column :label="$t('cip.dc.taskScheduling.label2.timeconsuming')" prop="address">
              <template slot-scope="scope">{{ (new Date(scope.row.endTime) - new Date(scope.row.startTime)) / 1000 + "s" }}</template>
            </el-table-column>
            <el-table-column :label="$t('cip.dc.taskScheduling.label2.state')" prop="status">
            </el-table-column>
            <el-table-column :label="$t('cip.dc.projectlist.seetabs.taskListlabel.details')">
              <template slot-scope="scope">
                <el-button type="text" @click="dialog3 = true">Details</el-button>
              </template>

            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane :label="$t('cip.dc.taskScheduling.title.Processlog')" name="third">


          <div style="white-space: pre-line;
"> {{ Seedata.resLog.data }}
          </div>

        </el-tab-pane>


        <el-dialog :append-to-body="true" :title="$t('cip.dc.taskScheduling.title.Joblog')" :visible.sync="dialog3" width="60%">
          <div style="white-space: pre-line;
"> {{ Seedata.resLog.data }}
          </div>

          <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dialog3 = false">{{ $t('projectlist.btn.close') }}</el-button>
    </span>
        </el-dialog>

      </el-tabs>



      <span slot="footer" class="dialog-footer">
  <el-button @click="dialog2 = false">{{ $t('projectlist.btn.close') }}</el-button>
</span>
    </el-dialog>


    <div style="display: none;">
      <gg></gg>
    </div>

  </div>
</template>

<script>
import {flowDetail, projectList, runFlow, ScheduleByCronEXEaFlow} from "@/api/dataAcquisition";
import g2 from "../p2.vue"
import CzrVueCron from "@/views/components/cron";
import GridLayout from "@/views/components/layout/grid-layout";
import gg from "../src/components/G6Editor/index.vue"
import eventBus from "@/util/eventBus"
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";

export default {
  name: "task1",
  data() {
    return {
      loading: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      Seedata: {
        resFetchexecflow: {
          nodes: {}
        },
        resLog: {
          data: ""
        }
      },

      radio: '',
      checkList: [],
      showcheckList: "",
      cronVal: '',
      DictList: [],//列表
      projectColumns: [],
      FlowId: "",

      activeName: 'first',
      Cg6: {},

      total: "", //总数

      listParms: {
        perPage: 20, startIndex: 1
      },
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
      gridRowBtn: [
        {
          label: this.$t('cip.dc.taskScheduling.btn.Scheduledscheduling'),
          emit: "grid-ds",
          type: "text",
          icon: ""
        },

        {
          label: this.$t('cip.dc.taskScheduling.btn.dispatch'),
          emit: "grid-dd",
          type: "text",
          icon: ""
        },
        {
          label: this.$t('cip.dc.taskScheduling.btn.see'),
          emit: "grid-see",
          type: "text",
          icon: ""
        },
      ],

      tableOptions: {
        customRowAddBtn: false,
        menuWidth: 300,
        height: 625,
        column: [
          {
            label: this.$t('cip.dc.taskScheduling.label1.name'),
            prop: "name",
            cell: true,
          },

          {
            label: this.$t('cip.dc.taskScheduling.label1.Chinesename'),
            prop: "zh_name",
            cell: true,
          },
          {
            label: this.$t('cip.dc.taskScheduling.label1.describe'),
            prop: "description",
            cell: true,
          },

          {
            label: this.$t('cip.dc.taskScheduling.label1.Lastmodificationtime'),
            prop: "modified_time",
            slot: true,

          },
          // {
          //   label: this.$t('taskScheduling.label1.Modifiedby'),
          //   prop: "last_modified_by",
          //   cell: true,
          // },
        ]
      },


    }
  },

  components: {
    g2, gg, CzrVueCron, GridLayout
  },
  mounted() {
    this.getProjectList(this.listParms)

    eventBus.$on("Sfirst", res => {
      this.getProjectList(this.listParms)
    })
  },
  created() {

  },
  methods: {

    //时间戳转化
    getDate(date) {
      var t = new Date(date).toLocaleString();
      return t;
    },
    SureCron() {
      this.dialog1 = false
      ScheduleByCronEXEaFlow({id: this.FlowId, cron: this.cronVal}).then(res => {
      })

    },
    //排序
    func(a, b) {
      return a - b
    },

    CHcList(e) {
      // this.showcheckList = this.checkList.sort(func)
      this.showcheckList = this.checkList.sort((a, b) => {
        return a - b
      }).toString()
    },

    getProjectList(e) {
      projectList(e).then(res => {
          this.projectColumns = res.data.data.list
          this.total = res.data.data.total
          console.log(this.projectColumns,'ssssss');
          this.$nextTick(() =>{
            this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
          })
          // console.log(this.projectColumns, 'this.projectColumns')
        
      })
    },
    //定时调度弹框
    DSclick(e) {
      this.dialog1 = true
      this.FlowId = e.projectid
    },

    //调度任务
    Rundd(e) {
      runFlow({id: e.projectid}).then(res => {
        if (res.data.code == 200) {
          this.$message({
            message: this.$t('omit.scheduledSuccess'),
            type: 'success'
          });
        }
      })
    },
    //分页
    handleSizeChange(val) {
      this.listParms.perPage = val.pageSize
      this.getProjectList(this.listParms)
    },
    handleCurrentChange(val) {
      this.listParms.startIndex = val.currentPage
      this.page.currentPage = val.currentPage
      this.getProjectList(this.listParms)
    },

    //查看详情
    MflowDetail(e) {

      flowDetail({execid: e.execid, id: e.projectid, offset: "0"}).then(res => {

        if (res.data.code == 200) {
          this.Seedata = res.data.data
          this.Cg6 = res.data.data.g6
          setTimeout(() => {
            eventBus.$emit('sendG62', this.Cg6)
          }, 1);


          this.dialog2 = true
        }
      })
    },


  }
}
</script>

<style scoped>
/* .cron .button[data-v-818e2f5e]{
  display: none;
} */
.block {
  float: right;

}

</style>
