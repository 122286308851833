<template>
   
   <div>

   
    


        <grid-layout ref="gridLayOut"
      :tableOptions="tableOptions"
      :gridRowBtn="gridRowBtn"
      :tableData="historyColumns"
      :data-total="total"
      :table-loading="loading"
      @page-size-change="handleSizeChange" @page-current-change="handleCurrentChange"
        >
      <template slot="start_time" slot-scope="{row}" >
        {{ getDate(row.start_time) }}
      </template>
      <template slot="end_time" slot-scope="{row}" >
        {{ getDate(row.end_time) }}
      </template>
      <template slot="elapsedTime" slot-scope="{row}" >
        {{ getElapsedTime(row.start_time,row.end_time) }}
      </template>
      <template slot="statusName" slot-scope="{row}" >
        <el-tag :type="historyTagColor(row)">{{historyTagText(row)}}</el-tag>
      </template>

    </grid-layout>







   





   



</div>
  </template>
  
  <script>
  import {getHistory} from "@/api/dataAcquisition";
  import eventBus from "@/util/eventBus"
  import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
  import GridLayout from "@/views/components/layout/grid-layout";

  export default {
    name: "task2",
    data(){
      return{
        dialog2:false,
        dialog3:false,
        historyColumns:[],

        total:"", //总数

      listParms:{
        perPage: 20, startIndex: 1
      },
      page: {
          pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
          currentPage: 1,
          total: 0
        },
        loading: false,
        gridRowBtn:[],
      tableOptions: {
        customRowAddBtn: false,
        menuWidth: 1,
        height:625,
          column: [
          {
            label: this.$t('taskScheduling.label2.Executionid'),
            prop: "exec_id",
            cell: true,
          },
          
          {
            label: this.$t('taskScheduling.label2.flow'),
            prop: "flow_id",
            cell: true,
          },
          {
            label: this.$t('taskScheduling.label2.engineering'),
            prop: "name",
            cell: true,
          },
            
            // {
            //   label: this.$t('cip.dc.taskScheduling.label2.user'),
            //   prop: "submit_user",
            //   cell: true,
             
            // },
            {
            label: this.$t('cip.dc.taskScheduling.label2.starttime'),
            prop: "start_time",
            cell: true,
            slot: true,
          },
          {
            label: this.$t('cip.dc.taskScheduling.label2.Endtime'),
            prop: "end_time",
            cell: true,
            slot: true,
          },
          {
            label: this.$t('cip.dc.taskScheduling.label2.timeconsuming'),
            prop: "elapsedTime",
            cell: true,
            slot: true,
          },
          {
            label: this.$t('cip.dc.taskScheduling.label2.state'),
            prop: "statusName",
            cell: true,
            slot: true,
          },
          ]
        },
        

      }
    },
    components:{
   GridLayout
  },
    mounted(){
      this.getHistoryList(this.listParms)
      eventBus.$on("Ssecond",res=>{
        this.getHistoryList(this.listParms)
    })
    },
    created(){
        
    },
    methods:{
      //时间戳转化
     getDate(date){ 
    var t = new Date(date).toLocaleString(); 
    return t; 
    },
        getElapsedTime(date1, date2) {
      if (null != date1 && "" != date1 && null != date2 && "" != date2) {
        var date3 = (date2 - date1); //时间差的毫秒数
        //计算出相差天数
        var days = Math.floor(date3 / (24 * 3600 * 1000));
        //计算出小时数
        var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
        var hours = Math.floor(leave1 / (3600 * 1000));
        //计算相差分钟数
        var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
        var minutes = Math.floor(leave2 / (60 * 1000));
        //计算相差秒数
        var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
        var seconds = Math.round(leave3 / 1000);
        //days + "天 " +
        return hours + this.$t('omit.hours') + minutes + this.$t('omit.minutes') + seconds + this.$t('omit.seconds');
      } else {
        return "";
      }
    },
    historyTagText(item){
      if (item.status == 30) {
        return this.$t('cip.dc.taskScheduling.status.Running')
      } else if (item.status == 50) {
        return this.$t('cip.dc.taskScheduling.status.success')
      } else if (item.status == 70) {
        return this.$t('cip.dc.taskScheduling.status.fail')
      }
    },
    historyTagColor(item){
      if (item.status == 30) {
        return `warning`
      } else if (item.status == 50) {
        return `success`
      } else if (item.status == 70) {
        return `danger`
      }else {
        return ''
      }
    },

    formateDateTime(val) {
      let time = new Date(val)
      let year = time.getFullYear(), hour = time.getHours(), month = time.getMonth() + 1, day = time.getDate(), minutes = time.getMinutes(), second = time.getSeconds();
      return `${year}-${this.formateNumber(month)}-${this.formateNumber(day)} ${this.formateNumber(hour)}:${this.formateNumber(minutes)}:${this.formateNumber(second)}`
    },
    formateNumber(val) {
       return val >= 10 ? val : '0' + val;
    },


    getHistoryList(e){
      getHistory(e).then(res=>{
        if(res.status==200){
          this.historyColumns=res.data.data.list
          this.total = res.data.data.total
        }

      })
    },


     //分页
     handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.listParms.perPage = val.pageSize
        this.getHistoryList(this.listParms)
      },
      handleCurrentChange(val) {
        this.listParms.startIndex = val.currentPage
        console.log(`当前页: ${val}`);
        this.getHistoryList(this.listParms)
      }

    
    }
  }
  </script>
  
  <style scoped>
 .block{
  float:right;
  
}
   ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 8px; 
    height: 8px; 
}

  </style>
  