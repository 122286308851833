  <template>
    <div>
     
    <head-layout
            :head-btn-options="headBtnOptions"
            :head-title="$t('cip.dc.taskScheduling.name')"
          >
          </head-layout>
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane :label="$t('cip.dc.taskScheduling.tabs.Projectlist')" name="first" >
          <task1></task1>
      </el-tab-pane>
      <el-tab-pane :label="$t('cip.dc.taskScheduling.tabs.Historylist')" name="second">
          <task2></task2>
      </el-tab-pane>
      <el-tab-pane :label="$t('cip.dc.taskScheduling.tabs.Scheduledtasklist')" name="third">
        <task3></task3>
      </el-tab-pane>
      <el-tab-pane :label="$t('cip.dc.taskScheduling.tabs.Runninglist')" name="fourth">
        <task4></task4>
      </el-tab-pane>
    </el-tabs>
    </div>
  </template>
<script>
import {getExecutor,delScheduleByScheduId,} from "@/api/dataAcquisition";
import task1 from "./compontents/task1.vue"
import task2 from "./compontents/task2.vue"
import task3 from "./compontents/task3.vue"
import task4 from "./compontents/task4.vue"
import eventBus from "../../../../util/eventBus"
import HeadLayout from "@/views/components/layout/head-layout"
export default {
  name: "taskScheduling",
  data(){
    return{

      activeName:'first',
      tableData: [],


    }


  },
  components:{
    task1,task2,task3,task4,HeadLayout
  },
  created() {




  },
  methods:{
   

    handleClick(e){
      if(e.name == "first"){
        eventBus.$emit("Sfirst","fff")
      }
      if(e.name == "second"){
        eventBus.$emit("Ssecond","fff")
      }
      if(e.name == "third"){
        eventBus.$emit("Sthird","fff")
      }
      if(e.name == "fourth"){
        eventBus.$emit("Sfourth","fff")
      }
    }





  }
}
</script>

<style scoped>
::v-deep .el-tabs__header{
  margin: 0 !important;
  background: #ffffff;
  padding: 0 12px !important;
}
</style>
